@font-face {
  font-family: 'theboldfont';
  src: local('theboldfont'), url(../../fonts/theboldfont.ttf) format('truetype');
}


footer {
  height: 5vh;
  width: 100%;
  /* margin: 0;
  padding-top: 2vh;
  margin-bottom: 3px;
  margin-top: auto; */
  margin: auto;
  overflow: hidden;
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #76df30a4 100%);



}

ul{
  display: flex;
  list-style-type: none;
  justify-content: space-around;
  color: aliceblue;
  font-family: 'theboldfont';
  font-variant: normal;
  
}

li > a {
  color: aliceblue;
  text-decoration: none;
}

li > a:hover {
  color: #ff097c;
}

@media (max-width: 1024px) {
  
  ul {
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
  }
}


@media (max-width: 800px) {
  
  ul {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-size: 10px;
  }
}

@media (max-width: 600px) {

  footer {
    margin: 0;
    padding: 0;
  }
  
  ul {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-size: 6px;
  }
}