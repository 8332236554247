@font-face {
  font-family: 'theboldfont';
  src: local('theboldfont'), url(fonts/theboldfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Arabian3';
  src: local('Arabian3'), url(fonts/Arabian3.ttf) format('truetype');
}


@font-face {
  font-family: 'Coalition';
  src: local('Coalition'), url(fonts/coalition/Coalition_v2..ttf) format('truetype');
}




body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;   
}


h1 {
  overflow-y: hidden;
}

/* SOUND GALLERY */



.title {
  padding-top: 130px !important;
}


.centered {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

/* Youtube section */

.wrapper-youtube {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 10vh;
  min-height: 95vh;
  padding-top: 100vh;

}

.wrapper-youtube h2 {
  flex: 0 1 100%;
  padding-bottom: 80px;
  padding-top: 40px;
  text-align: center;
  font-size: 7rem;
  font-family: 'theboldfont';
}

.video-format {
  width: 800px;
  height: 450px;
}


#bazar {
  color: #9808f8;
  text-shadow: 7px 2px 2px #a46dc8;
}

#sounge {
  color: #0b7af8;
  text-shadow: 7px 2px 2px #5186c3;

}

#theDuet {
  color: #ff097c;
  text-shadow: 7px 2px 2px rgb(167, 145, 145);
}

#sundaz {
  color: #fff706;
  text-shadow: 7px 2px 2px #61603c;
  
}

#Solidbody{
  color: #27F23F;
  text-shadow: 7px 2px 2px #73b87b;
}



/* BANDCAMP SUNDAZ */

.bandcamp {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; 
  column-gap: 8vw;
}

.bandcamp iframe{

  margin-bottom: 3rem;
}

.icon{
  display: inline ;
  margin-top: 50px;
  margin-left: 20px;
  background-color: inherit;
  border: none;
  color: rgb(13, 221, 221);
  position: fixed;
}


.icon:hover {
  transition: 0.5s;
  transform: scale(1.2);
}

.soundcloud {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}


.soundcloud > * {
  margin-bottom: 3rem;
}


.bandcamp-theduet {
  display: flex;
  justify-content: center;
}

.banner {
  height: 100vh;
  width: 100vw; 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  z-index: -9999;
}

#sounge-banner {
  background-image: url("../src/assets/logo/sounge.png");
}

#sundaz-banner {
  background-image: url("../src/assets/logo/sundaz.png");
}

#solidbody-banner {
  background-image: url("../src/assets/logo/solidbody.png");
}

#neo-banner {
  background-image: url("../src/assets/logo/neo.png");
}

#neo-title {
  color: white;
  font-family: 'Coalition';
  font-size: 300px;
  letter-spacing: 30px;
  z-index:999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 
}

#theduet-banner-background-bigscreen {
  background: linear-gradient(90deg, rgba(234, 106, 198, 0.61) 0%, rgba(56, 62, 220, 0.86) 100%);
}

#theduet-banner-background-tablet {
  background: linear-gradient(143.86deg, rgba(234, 106, 198, 0.61) 0%, rgba(56, 62, 220, 0.86) 100%);
}

#allywood-banner {
  background-image: url("../src/assets/logo/allywood_laptop.png");
  background-size: cover;
}

#aftertime-banner {
  background-image: url("../src/assets/logo/aftertime_laptop.png");
  background-size: cover;
}  

.banner-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 200px;
  color: #48beff;
}

.bazar {
  font-family: 'Arabian3';
  font-size: 25vw;
}

/* THE DUET LOGO ANIMATION */



#theduet-logo-bigscreen path:nth-child(2) {
  stroke-dasharray: 718;
  stroke-dashoffset: 718;
  animation: line-anim 2s ease forwards 0.3s;

}

#theduet-logo-bigscreen path:nth-child(3) {
  stroke-dasharray: 744;
  stroke-dashoffset: 744;
  animation: line-anim 2s ease forwards 0.6s;
}

#theduet-logo-bigscreen path:nth-child(4) {
  stroke-dasharray: 744;
  stroke-dashoffset: 744;
  animation: line-anim 2s ease forwards 0.9s;
}

#theduet-logo-bigscreen path:nth-child(5) {
  stroke-dasharray: 522;
  stroke-dashoffset: 522;
  animation: line-anim 2s ease forwards 1.2s;
}

#theduet-logo-bigscreen path:nth-child(6) {
  stroke-dasharray: 679;
  stroke-dashoffset: 679;
  animation: line-anim 2s ease forwards 1.5s;
}

#theduet-logo-bigscreen path:nth-child(7) {
  stroke-dasharray: 744;
  stroke-dashoffset: 744;
  animation: line-anim 2s ease forwards 1.8s;
}

#theduet-logo-bigscreen path:nth-child(8) {
  stroke-dasharray: 522;
  stroke-dashoffset: 522;
  animation: line-anim 2s ease forwards 2.1s;
}


/* ANIMATION THE DUET TABLET */


#theduet-logo-tablet path:nth-child(2) {
  stroke-dasharray: 718;
  stroke-dashoffset: 718;
  animation: line-anim 2s ease forwards 0.3s;

}

#theduet-logo-tablet path:nth-child(3) {
  stroke-dasharray: 744;
  stroke-dashoffset: 744;
  animation: line-anim 2s ease forwards 0.6s;
}

#theduet-logo-tablet path:nth-child(4) {
  stroke-dasharray: 744;
  stroke-dashoffset: 744;
  animation: line-anim 2s ease forwards 0.9s;
}

#theduet-logo-tablet path:nth-child(5) {
  stroke-dasharray: 522;
  stroke-dashoffset: 522;
  animation: line-anim 2s ease forwards 1.2s;
}

#theduet-logo-tablet path:nth-child(6) {
  stroke-dasharray: 679;
  stroke-dashoffset: 679;
  animation: line-anim 2s ease forwards 1.5s;
}

#theduet-logo-tablet path:nth-child(7) {
  stroke-dasharray: 744;
  stroke-dashoffset: 744;
  animation: line-anim 2s ease forwards 1.8s;
}

#theduet-logo-tablet path:nth-child(8) {
  stroke-dasharray: 522;
  stroke-dashoffset: 522;
  animation: line-anim 2s ease forwards 2.1s;
}

#theduet-logo-tablet {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
}


@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}


/* ANIMATION BAZAR */

#bazar-svg {


}

#draw-line {
  fill: none;
  stroke-width: 1px;
  /* Stroke-dasharray property */
  stroke-dasharray: 1500; 
  stroke-dashoffset: 1500;

 animation: move 10s linear; 
 animation-fill-mode: forwards;

 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%) scale(2);
  
}

#draw-line:circle {
  background-color: aqua;
}



@keyframes move {
  to {
      stroke-dashoffset: 0;
  }
}

#bazar-banner {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 231, 13, 0.6264) 0%, rgba(252, 236, 94, 0.5616) 91.15%);
}


#bazar-svg {
  animation: blink 5s ease ;
}


@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* trick pour resize la video youtube pour des écrans inférieurs à 600 px */

@media screen and (max-width: 1024px) {

  .youtube-video {
    position: relative;
    width: 80%;
    padding-bottom: 45%; 
    height: 0;
  }

  .youtube-video iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }


.soundcloud iframe{
  position: relative;
  width: 80%;
  height: 100%;
}

.wrapper-youtube h2 {
  flex: 0 1 100%;
  padding-bottom: 80px;
  padding-top: 40px;
  text-align: center;
  font-size: 6rem;
  font-family: 'theboldfont';
  
}

.wrapper-youtube {
  min-height: auto;

  }

.banner {
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}


/* CELLPHONE PORTRAIT  */

#sounge-banner {
  background-image: url("../src/assets/logo/sounge_cellphone_portrait.png");
}

#sundaz-banner {
  background-image: url("../src/assets/logo/sundaz_cellphone_portrait.png");
}

#solidbody-banner {
  background-image: url("../src/assets/logo/solidbody_cellphone_portrait.png");
}

#allywood-banner {
  background-image: url("../src/assets/logo/allywood_cellphone_portrait.png");
}




}



/* TABLET PORTRAIT */

@media (min-width: 500px) and (max-width: 1025px) {
  #solidbody-banner {
    background-image: url("../src/assets/logo/vertical_solidbody.png");
  }

  #sounge-banner {
    background-image: url("../src/assets/logo/sounge_tablet.png");
  }

  #allywood-banner {
    background-image: url("../src/assets/logo/allywood_tablet_portrait.png");
  }

  #neo-title {
    font-size: 200px;
  }
   
}


/* TABLET LANDSCAPE */

@media (min-width: 1024px) and (max-width: 1200px) {
  #sounge-banner {
    background-image: url("../src/assets/logo/sounge_tablet_landscape.png");
  }

  #allywood-banner {
    background-image: url("../src/assets/logo/allywood_tablet_landscape.png");
  }

  #neo-title {
    font-size: 200px;
  }

}



/* Ipad pro  Landscape*/

@media (min-width: 1300px) and (max-width: 1400px) {
  #allywood-banner {
    background-image: url("../src/assets/logo/allywood_tablet_landscape.png");
  }

}


/* Ipad pro portrait*/

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait)  {
  #allywood-banner {
    background-image: url("../src/assets/logo/allywood_cellphone_portrait.png");
  }

}




 /* CELLPHONES  */
 @media (max-width: 767px) {
  .wrapper-youtube h2 {
    flex: 0 1 100%;
    padding-bottom: 80px;
    padding-top: 40px;
    text-align: center;
    font-size: 3rem;
    font-family: 'theboldfont';
    }
}

@media (max-width: 767px) {
  .wrapper-youtube {
    min-height: auto;

    }

  #neo-title {
     font-size: 90px;
     padding-left: 35px;
  }
}

/* LANDSCAPE CELLPHONE  */

@media (max-height: 420px ) {
  #sundaz-banner {
    background-image: url("../src/assets/logo/sundaz_cellphone_landscape.png");
  }

  #solidbody-banner {
    background-image: url("../src/assets/logo/solidbody_cellphone_landscape.png");
  }

  #sounge-banner {
    background-image: url("../src/assets/logo/sounge_cellphone_landscape.png");
  }

  #allywood-banner {
    background-image: url("../src/assets/logo/allywood_cellphone_landscape.png");
  }


}

/* fin du trick */

@media (min-width: 1100px) {

 
  
}

.error404 {
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #76df30a4 100%);
  font-family: 'theboldfont';
  font-size: calc(1rem + 2vw);
  color: #655560;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

