@font-face {
  font-family: 'theboldfont';
  src: local('theboldfont'), url(../../fonts/theboldfont.ttf) format('truetype');
}


.wrapper {

  display: flex;
  /* padding-top: 80px; */
  height: 95vh;
  align-items: center;
  justify-content: space-around;
  flex: 1 1 auto;
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #76df30a4 100%);


}

.container {
  height: 100vh;
}

.wrapper a {
  font-family: 'theboldfont';
  color: #655560;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);

  /* letter-spacing: -25px; */
  display: inherit;
  
}


.sounge {
  font-size: 7rem;
  writing-mode: vertical-rl;
  order: 4;


}

.sundaz {
  font-size: 7rem;
  writing-mode: vertical-rl;
  order: 1;
}

.theDuet {
  font-size: 7rem;
  writing-mode: vertical-rl;
  white-space: nowrap;
  /* word-spacing: -.4ch; */
  order: 2;

}


.Bazar {
  font-size: 7rem;    
  writing-mode: vertical-rl;
  order: 5;  
}

.Solidbody {
  font-size: 7rem;    
  writing-mode: vertical-rl;
  order: 3; 
}

.Allywood {
  font-size: 7rem;    
  writing-mode: vertical-rl;
  order: 6; 
}

.Aftertime {
  font-size: 7rem;    
  order: 7; 
}


.Neo {
  font-size: 7rem;
}

.Bazar a:hover {
  color: #9808f8;
}

.theDuet a:hover {
  color: #ff097c;
}


.sundaz a:hover {
  color: #fff706;
}

.sounge a:hover {
  color: #0b7af8;
}

.Solidbody a:hover {
  color: #27F23F;
}

.Allywood a:hover {
  color: #f69f08;
}

.Aftertime a:hover {
  color:#6520e7;
}

.Neo a:hover {
  color: #1acd1a;
}

 /* animation de fifou rotate + logo  */

/* .sundaz a:hover {
  color: #fff706; 
  content: url(../../assets/logo/sundaz_cellphone_portrait.png);
  display: block;
  transform: rotate(0deg);
  opacity: 0.8;
  transition: 0.3s;
  transform: scale(0.7);
  
}

.sounge a:hover {
  color: #0b7af8;
  content: url(../../assets/logo/sounge_cellphone_portrait.png);
  display: block;
  transform: rotate(0deg);
  opacity: 0.8;
  transition: 0.3s;
  transform: scale(0.7);
  
} */


/* .Solidbody a:hover {
  color: #27F23F;
  content: url(../../assets/logo/solidbody_cellphone_portrait.png);
  display: block;
  transform: scale(0.7);

  
} */






 /* CELLPHONE */

@media (max-width: 767px) {

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

  }


  .wrapper a {
    transform: rotate(-90deg);

  }

  .Neo a {
    font-size: 1.3rem;
  }

  .sounge a {
    font-size: 1.3rem;

  }

  .sundaz a {
    font-size: 1.3rem;


  }
  
  .theDuet a {
    font-size: 1.3rem;


  }

  .Bazar a{
    font-size: 1.3rem;
  


  }

  .Solidbody a{
    font-size: 1.3rem;
   

  }

  .Allywood a {
    font-size: 1.3rem;
  }

  .Aftertime a {
    font-size: 1.3rem;
  }

}



/* IPAD PRO*/


@media (min-width: 767px ) and (max-width: 1024px) {

  .wrapper {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }


  .wrapper a {
    transform: rotate(-90deg);
  }

  .Neo a {
    font-size: 2rem;
  }

  .sounge a {
    font-size: 2rem;
    }

  .sundaz a {
    font-size: 2rem;
  }
  
  .theDuet a {
    font-size: 2rem;
  }

  .Bazar a{
    font-size: 2rem;  }

  .Solidbody a{
    font-size: 2rem;
  }

  .Aftertime a {
    font-size: 2rem;
  }

  .Allywood a {
    font-size: 2rem;
  }
}


/* REGULAR IPAD */


@media (min-width: 600px ) and (max-width: 800px) {


  .container {
    height: 100vh;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 95vh;

  }


  .wrapper a {
    transform: rotate(-90deg);

  }

  .Neo a {
    font-size: 1.8rem;
  
  }

    .sounge a {
    font-size: 1.8rem;
  }

  .sundaz a {
    font-size: 1.8rem;
  }
  
  .theDuet a {
    font-size: 1.8rem;
  }

  .Bazar a{
    font-size: 1.8rem;
  }

  .Solidbody a{
    font-size: 1.8rem;
  }


  .Aftertime a {
    font-size: 1.8rem;
  }

  .Allywood a {
    font-size: 1.8rem;
  }
  
}

@media (orientation: landscape) {
  .wrapper {
    display: flex;
    flex-direction: row;
  /* padding-top: 80px; */
    height: 95vh;
    align-items: center;
    justify-content: space-around;
    flex: 1 1 auto;
  }  

  .wrapper a {
    font-family: 'theboldfont';
    color: #655560;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    transform: rotate(-180deg);
  
    /* letter-spacing: -25px; */
    display: inherit;
    
  }

  
}